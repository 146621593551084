.gavel-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  pointer-events: none;
  background-color: #516170;
  border: 100px solid #516170;
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
}

.gavel-animation .draw-hammer {
  width: 500px;
  stroke: #ffffff;
  stroke-width: 0.8311;
  stroke-dasharray: 175px;
  stroke-dashoffset: 0;
  overflow: visible;
}

.gavel-animation .hammer-fill {
  stroke-dasharray: 125px;
  stroke-dashoffset: 0;
}

.gavel-animation .head {
  fill: #333;
  fill: linear-gradient(to bottom, #555 0%, #333 100%);
}

.gavel-animation .joint {
  fill: #747474;
  fill: linear-gradient(to bottom, #848484 0%, #696464 100%);
}

.gavel-animation .shaft {
  fill: #8b4513;
  fill: linear-gradient(to bottom, #8b4513 0%, #654321 100%);
}

.gavel-animation .base {
  fill: #582805;
}

.gavel-animation .hammer {
  animation-name: pound;
  animation-duration: 1.1s;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform-origin: right bottom;
  transform: translateY(-18px) translateX(3px);
}

@keyframes pound {
  20% {
    transform: translateY(-18px) translateX(3px) rotate(-41deg);
  }
  21% {
    transform: translateY(-18px) translateX(3px) rotate(-41deg);
  }
  100% {
    transform: translateY(-18px) translateX(3px) rotate(-32deg);
  }
}
