body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
  --yellow: rgb(255, 243, 43);
  --blue: rgb(74, 193, 236);
  --orange: rgb(252, 170, 26);
  --green: rgb(112, 190, 78);
  --red: rgb(238, 46, 43);
  --moneycolor: rgb(152, 236, 69);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* MAIN */
.App {
  text-align: center;
}

.container {
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
  align-items: center;
}

/* UI TITLE */
.ui-title {
  z-index: 100;
  font-family: "Roboto", sans-serif;
  font-size: clamp(1rem, 2.5vw, 2rem);
}

@media (max-width: 1023px) {
  .ui-title {
    font-size: clamp(0.5rem, 1.25vw, 1rem);
  }
}

.ui-title h3 {
  margin: 0.2rem;
}

.ui-title div {
  display: flex;
  justify-content: center;
}

.debug-hidden {
  display: none !important;
}

/* UI OVAL */
.ui-oval-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.ui-oval {
  width: 88%;
  aspect-ratio: 2;
  background-color: #2b2f38;
  border: 1px solid #000;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
}

.ui-oval .ui-block1 {
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  align-content: end;
  justify-content: end;
  height: 55%;
}

.ui-oval .ui-block2 {
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  align-content: end;
  justify-content: start;
  height: 55%;
}
.ui-oval .ui-block3 {
  flex: 1 1 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 45%;
}

/* HELP BOX */
.help-box {
  position: fixed;
  top: 1.5vw;
  left: 1.5vw;
  width: clamp(1.3rem, 2.5vw, 3rem);
  height: clamp(1.3rem, 2.5vw, 3rem);
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  z-index: 9999;
}

.help-icon {
  font-size: clamp(1rem, 2.5vw, 1.6rem);
  color: #000000;
}

.help-box-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
  font-size: clamp(0.6rem, 1.5vw, 1.2rem);
  background-color: #516170;
  border-radius: 1rem;
  border: 1px solid #000000;
  padding: clamp(1rem, 1vw, 2rem);
  z-index: 9999;
  min-width: 70%;
  max-width: 85vw;
  max-height: 80vh;
  overflow: auto;
}

.icon-table {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.help-text-cell {
  padding: 0.625rem;
  text-align: justify;
}

.card-icon-help {
  height: clamp(0.9rem, 1.5vw, 2.5rem);
  width: clamp(0.9rem, 1.5vw, 2.5rem);
  padding: clamp(0.1rem, 0.8vw, 1rem);
  border: 2px solid black;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-credits {
  font-size: clamp(0.4rem, 1.5vw, 0.8rem);
}

/* GAME OVER OVERLAY */

.game-over-overlay {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1rem, 1.5vw, 2rem);
  background-color: rgba(81, 97, 112, 0.8);
  border-radius: 1rem;
  border: 1px solid #000000;
  padding: clamp(1rem, 1vw, 2rem);
  z-index: 899;
  min-width: 30%;
  max-width: 55vw;
  max-height: 80vh;
  overflow: auto;
}

/* GAMEBOARD */

.ui-gameboard {
  width: min-content;
  margin-right: 10%;
  margin-bottom: 10%;
}

.ui-gameboard-table {
  border-collapse: collapse;
  width: 100%;
  font-size: clamp(0.25rem, 0.7vw, 1.6rem);
}

.token {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: clamp(0.25rem, 0.3vw, 0.8rem);
  height: clamp(0.25rem, 0.3vw, 0.8rem);
  border-radius: 50%;
  border: 2px solid #000;
  padding: clamp(0.25rem, 0.4vw, 0.8rem);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-family: Roboto, sans-serif;
  font-size: clamp(0.25rem, 0.6vw, 0.8rem);
}

.current-round .token:not(.token-value-0) {
  opacity: 0.5;
}

@media (max-width: 1919px) {
  .ui-gameboard {
    top: 30%;
    left: 20%;
  }
}

@media (max-width: 1599px) {
  .player-paintings-bought .color-card {
    width: clamp(0.4rem, 1.5vw, 1rem);
    height: clamp(0.6rem, 2.25vw, 1.5rem);
    border-radius: 8%;
    margin-right: 1px;
  }
}

@media (max-width: 1365px) {
  .ui-gameboard {
    top: 25%;
    left: 20%;
  }
}

@media (max-width: 1023px) {
  .ui-gameboard {
    top: 20%;
    left: 15%;
  }

  .ui-gameboard-table {
    font-size: clamp(0.25rem, 0.7vw, 1.6rem);
  }

  .token {
    width: clamp(0.25rem, 0.25vw, 0.8rem);
    height: clamp(0.25rem, 0.25vw, 0.8rem);
    padding: clamp(0.25rem, 0.25vw, 0.8rem);
    font-size: clamp(0.25rem, 0.6vw, 0.8rem);
    border: 1px solid #000;
  }

  .player-paintings-bought .color-card {
    width: clamp(0.4rem, 1.5vw, 1rem);
    height: clamp(0.6rem, 2.25vw, 1.5rem);
    border-radius: 8%;
    margin-right: 1px;
  }
}

@media (max-width: 853px) {
  .ui-gameboard {
    top: 20%;
    left: 15%;
  }

  .player-paintings-bought .color-card {
    width: clamp(0.3rem, 1.2vw, 0.8rem);
    height: clamp(0.45rem, 1.8vw, 1.2rem);
    border-radius: 8%;
    margin-right: 1px;
  }
}

.ui-gameboard .marker-td {
  max-width: 2ch;
}

.ui-gameboard .content {
  min-width: 8.5ch;
}

.token-tr {
  height: clamp(0.25rem, 0.6vw, 0.8rem);
}

.header-cell-card-artist {
  font-family: "Montserrat";
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  height: 2.5ch;
}

.token-value-10 {
  background-color: #dedede;
  color: rgb(32, 32, 32);
}

.token-value-20 {
  background-color: #474747;
  color: rgb(231, 231, 231);
}

.token-value-30 {
  background-color: #202020;
  color: white;
}

.token-value-0 {
  border-color: rgba(255, 255, 255, 0.6);
  background-color: transparent;
  color: transparent;
}

/* PLAYER ICONS */

.player-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: clamp(2.1rem, 3.7vw, 4.6rem);
  width: clamp(2.1rem, 3.7vw, 4.6rem);
  font-size: clamp(0.8rem, 1.5vw, 1.2rem);
  font-family: "Montserrat";
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  background-color: #4a4a4a;
}

@media (max-width: 853px) {
  .player-icon {
    height: clamp(1.3rem, 2.9vw, 3.9rem);
    width: clamp(1.3rem, 2.9vw, 3.9rem);
    font-size: clamp(0.5rem, 1.2vw, 0.9rem);
  }
}

.player-icon.active {
  background-color: #965cbd;
}

.player-paintings-bought {
  display: flex;
}

.color-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(0.5rem, 2vw, 1.5rem);
  height: clamp(0.75rem, 3vw, 2.25rem);
  border-radius: 8%;
  margin-right: 2px;
  border: black solid 1px;
}

.yellow {
  background-color: rgb(255, 243, 43);
}

.blue {
  background-color: rgb(74, 193, 236);
}

.orange {
  background-color: rgb(252, 170, 26);
}

.green {
  background-color: rgb(112, 190, 78);
}

.red {
  background-color: rgb(238, 46, 43);
}

.transparent {
  background-color: #282c34;
}

.count {
  font-weight: bold;
  font-size: clamp(0.6rem, 1.2vw, 1rem);
  font-family: Roboto;
  color: black;
}

.ui-money {
  font-size: clamp(1.2rem, 1.5vw, 3rem);
  font-family: Roboto;
  color: rgb(116, 194, 39);
}

/* PLAYER HAND AND CARDS*/

.ui-hand {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  display: flex;
}

.ui-hand .card-grid {
  display: flex;
  transition: ease-in-out 0.4s transform;
}

.ui-hand .card-grid .card {
  width: 100px;
}

/*THIS IS THE SIZE OF A CARD*/
.card-grid .card-wrapper {
  width: 225px;
}

@media (max-width: 2559px) {
  .ui-hand {
    transform: translateY(20%);
  }
}

@media (max-width: 1919px) {
  .card-grid .card-wrapper {
    width: 175px;
  }
  .ui-hand {
    transform: translateY(10%);
  }

  .card-grid .card-icon {
    border: 1px solid black;
  }
  .card-grid .card-header {
    border-bottom: 1px solid black;
  }
}

@media (max-width: 1599px) {
  .ui-hand {
    transform: translateY(20%);
  }
  .card-grid .card-wrapper {
    width: 150px;
  }

  .card-grid .card-header {
    justify-content: flex-start;
    height: 15%;
    padding-top: 3%;
  }
}

@media (max-width: 1365px) {
  button,
  select,
  input {
    font-size: clamp(0.3rem, 0.75vw, 1rem);
  }

  .card-grid .card-wrapper {
    width: 125px;
  }
  .ui-hand {
    transform: translateY(15%);
  }

  .card-grid .card-header {
    justify-content: flex-start;
    height: 17%;
    padding-top: 5%;
  }
}

@media (max-width: 1023px) {
  .card-grid .card-wrapper {
    width: 100px;
  }
  .ui-hand {
    transform: translateY(16%);
  }
  .card-grid .card-icon {
    border: 1px solid black;
  }
  .card-grid .card-header {
    justify-content: flex-start;
    height: 18%;
    border-bottom: 1px solid black;
    padding-top: 5%;
  }
}

@media (max-width: 853px) {
  .card-grid .card-wrapper {
    width: 80px;
  }
  .ui-hand {
    transform: translateY(20%);
  }
  .card-grid .card-icon {
    border: 1px solid black;
  }
  .card-grid .card-header {
    justify-content: flex-start;
    height: 20%;
    border-bottom: 1px solid black;
    padding-top: 5%;
  }
  button,
  select,
  input {
    font-size: clamp(0.2rem, 0.65vw, 0.9rem);
  }
}

.card-grid .card-wrapper.selected,
.card-grid .card-wrapper:hover {
  transform: scale(1.02) !important;
  z-index: 20 !important;
}
.card-grid .card-wrapper.selected {
  border: 2px solid white;
  box-sizing: border-box;
  transform: scale(1.02) translateY(-5%) !important;
}

.card-grid .disabled {
  pointer-events: none;
  cursor: default;
}

.card-grid .disabled:hover {
  transform: scale(1);
}

.card-image {
  position: relative;
  z-index: 1;
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 12%;
  z-index: 2;
  color: #000;
  border-bottom: 2px solid black;
}

.card-artist {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(0.5rem, 0.8vw, 1rem);
  font-weight: bold;
  text-transform: uppercase;
}

.card-artwork-name {
  font-family: "Roboto", sans-serif;
  font-size: clamp(0.5rem, 0.4vw, 0.625rem);
}

.card-icon {
  position: absolute;
  left: 6%;
  bottom: 0;

  transform: translateY(50%);
  border: 2px solid black;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-grid .card-icon {
  height: clamp(0.35rem, 1.5vw, 2.18rem);
  width: clamp(0.35rem, 1.5vw, 2.18rem);
}

.card-icon svg {
  width: 90%;
}

.card-wrapper {
  position: relative;
  border-radius: 8%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  font-size: 0;
  transition: ease-in-out 0.2s transform;
}

/* AUCTION CARDS */

.ui-auctioncards {
  font-size: clamp(0.6rem, 0.6vw, 0.825rem);
  margin-left: 5%;
}

.ui-auctioncards .auction-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui-auctioncards .auction-cards .card-wrapper {
  width: 300px;
  margin: 0 5px;
}

.ui-auctioncards .auction-cards .card-icon {
  height: clamp(0.35rem, 1.5vw, 2.18rem);
  width: clamp(0.35rem, 1.5vw, 2.18rem);
}

@media (max-width: 2559px) {
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 200px;
    margin: 0 4px;
  }
}

@media (max-width: 1919px) {
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 175px;
    margin: 0 3px;
  }
}

@media (max-width: 1599px) {
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 150px;
    margin: 0 3px;
  }
  .ui-auctioncards .auction-cards .card-icon {
    border: 1px solid black;
  }
  .ui-auctioncards .auction-cards .card-header {
    border-bottom: 1px solid black;
    justify-content: flex-start;
    height: 15%;
    padding-top: 5%;
  }
}

@media (max-width: 1365px) {
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 135px;
    margin: 0 2px;
  }
}

@media (max-width: 1279px) {
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 125px;
    margin: 0 2px;
  }
}

@media (max-width: 1023px) {
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 110px;
    margin: 0 1px;
  }
}

@media (max-width: 853px) {
  .ui-auctioncards {
    font-size: clamp(0.3rem, 0.3vw, 0.425rem);
    transform: translateY(5%);
  }
  .ui-auctioncards .auction-cards .card-wrapper {
    width: 80px;
    margin: 0 1px;
  }
  .ui-auctioncards .auction-cards .card-header {
    justify-content: flex-start;
    height: 20%;
    padding-top: 5%;
  }
}

/* PLAYER AUCTION DIALOG */

.ui-playerauctiondialog {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(0.4rem, 0.7vw, 1.2rem);
  margin-top: 1%;
  width: 60%;
}

@media (max-width: 2559px) {
}

@media (max-width: 1919px) {
}

@media (max-width: 1365px) {
}

@media (max-width: 1023px) {
}

@media (max-width: 853px) {
  .ui-playerauctiondialog {
    font-size: clamp(0.3rem, 0.6vw, 1.1rem);
    transform: translateY(5%);
  }
}

/* GAMELOG */

.log-player-name {
  font-weight: bold;
}

.log-money {
  color: var(--moneycolor);
}

.ui-gamelog {
  font-size: clamp(0.7rem, 0.7vw, 1rem);
  /* font-family: "Roboto", sans-serif; */
  position: fixed;
  top: 1.5vw;
  right: 1.5vw;
  height: 10%;
  width: 25%;
  border: solid black 1px;
  background-color: #2b2f38;
  overflow-y: scroll;
  z-index: 50;
}

@media (max-width: 853px) {
  .ui-gamelog {
    font-size: clamp(0.3rem, 0.6vw, 1.1rem);
  }
  .gamelog-list li {
    margin-bottom: 1px;
  }
}

.gamelog-list {
  list-style-type: none;
  padding: 0px;
  margin: 0;
  text-align: left;
}

.gamelog-list li {
  margin-bottom: 2px;
}

/* Styling for the game creator container */
.game-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  background-color: #dddddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 20rem;
  margin-top: 3rem;
}

/* Styling for the input fields */
.game-creator input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Styling for the buttons */
.game-creator button {
  padding: 10px 20px;
  margin: 5px;
  font-size: 1rem;
  color: #fff;
  background-color: #454f63;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.game-creator button:hover {
  background-color: #576788;
}

.game-creator button:active {
  background-color: #576788;
}

.game-creator .ui-title {
  margin-bottom: 1.2rem;
}

.game-creator .ui-title h3 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  font-family: "Roboto", sans-serif;
}

/* Tooltip CSS */

.tooltip {
  position: absolute;
  display: grid;
  bottom: 110%;
  font-family: "Montserrat", sans-serif;
  font-size: clamp(0.6rem, 1.5vw, 1.2rem);
  transform: translateX(-2.2ch);
  padding: 6px;
  background-color: #516170;
  width: 22ch;
  height: 6ch;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.tooltip-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-icon {
  position: relative;
  height: clamp(0.35rem, 1.5vw, 2.18rem);
  width: clamp(0.35rem, 1.5vw, 2.18rem);
  border: 2px solid black;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.tooltip-icon svg {
  width: 90%;
}

.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.modal-content {
  background-color: rgba(81, 97, 112, 0.8); /* semi-opaque background color */
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
